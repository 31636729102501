import React from "react";

// MATERIAL UI
import { Typography, Divider } from "@mui/material";

import Layout from "components/Layout";

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Typography variant="h1" sx={{ textAlign: "center", mb: 10, mt: 4 }}>
        Políticas de privacidad
      </Typography>

      <div className="pp-ctr">
        <p>Last Updated: May 27, 2020</p>
        <p>
          We wrote this policy to help you understand what information we
          collect, how we collect it and how we strote users information, whitin
          Pau's system.
          <Spacer />
          Our goal is to give our users the best experience using Pau's
          services.
        </p>
        <Divider />

        <Typography variant="h3">
          What categories of information we may collect?
        </Typography>
        <p>
          <b>"Personal Information": </b> We restrict ourselves to obtain only
          the user's email and user name for authentication purposes in the
          application.
          <Spacer />
          <b>"Customer information"</b> We collect information users provide
          related with Pau functionality such as urls users uploads to Pau
          system. Users will control what data is sent to and stored on our
          systems. For example, users might upload urls info in order to
          navigate through them when they use Pau, or user configuration about
          Pau system for example: pau theme (light or dark mode)
          <Spacer />
          <b>"Use and location data"</b> We get some of your data by collecting
          events of how you interact with our website and our Services. We may
          collect data about the features you use. This also includes your
          interactions on our website, your feature usage patterns and location
          data.
          <Spacer />
          <b>"User interactions with UI"</b> We use Splitbee and
          MixPanel in order to track some interactions users do with Pau
          UI, although this information is completely anonymous, which means we
          dont attach any user identifiable information with this actions. We
          collect this type of information to improve our users experience when
          using Pau.
        </p>
        <Divider />

        <Typography variant="h3">
          What do we use users information for?
        </Typography>
        <p>
          We use the data we collect from our users to operate our business, to
          provide the services pau brings to our users and identify our users
          when them use Pau systems.
        </p>
        <Divider />

        <Typography variant="h3">
          How we collect or create information?
        </Typography>
        <p>
          We may collect or obtain user information directly from our users.
          When users create a new Link Group or Link whitin a Card in Pau system
          or when users update their configuration about Pau system
        </p>
        <Divider />

        <Typography variant="h3">Do we encrypt users information?</Typography>
        <p>
          Yes. We encrypt users information they create before store it in our
          databases. We use algorithms Google recomends for this to ensure our
          users information securety.
        </p>
        <Divider />

        <Typography variant="h3">Do we share users information?</Typography>
        <p>
          No, we are completely restricted to share the information of our users
          with other systems.
        </p>
        <Divider />

        <Typography variant="h3">Do we use cookies?</Typography>
        <p>
          Yes, we use cookies and other similar identifiers to understand and
          save your preferences for future visits
        </p>
        <Divider />

        <Typography variant="h3">Data Retention</Typography>
        <p>
          We may retain your personal information as long as you continue to use
          the Services, have an account with us, or for as long as is necessary
          to fulfill the purposes outlined in this Policy. You can ask to close
          your account by contacting us, and we will delete your personal
          information on request
        </p>
      </div>
    </Layout>
  );
};

const Spacer = () => (
  <>
    <br />
    <br />
  </>
);

export default PrivacyPolicyPage;
